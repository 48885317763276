import React from 'react';
import $ from 'jquery';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const FormWizard = React.lazy(() => import('./Pages/Forms/index'));
const Landing = React.lazy(() => import('./Pages/Landing/index'));
const Signup = React.lazy(() => import('./Pages/Landing/Signup/index'));
const TermsPage = React.lazy(() => import('./Pages/Landing/Terms/index'));
const PrivacyPage = React.lazy(() => import('./Pages/Landing/Privacy/index'));
const SupportPage = React.lazy(() => import('./Pages/Landing/Support/index'));
const BusinessDevAssociatePage = React.lazy(() => import('./Pages/Landing/Postings/business_dev_associate'));
const PrescreeningForm = React.lazy(() => import('./Pages/Prescreen/index'));
const SignupConfirmation = React.lazy(() => import('./Pages/Landing/Signup/confirmation'));
const ReviewForm = React.lazy(() => import('./Pages/ReviewForm'));

const route = [
    { path: '/', exact: true, name: 'Qwisely', component: FormWizard },
    { path: '/prescreen/:businessId/:prescreenClientId', exact: true, name: 'Qwisely Prescreening Form', component: PrescreeningForm },
    { path: '/:businessId/:clientId', exact: true, name: 'Qwisely Form', component: FormWizard },
    { path: '/about', exact: true, name: 'Qwisely by Ming Microsystems', component: Landing },
    { path: '/terms', exact: true, name: 'Terms & Conditions | Ming Microsystems', component: TermsPage },
    { path: '/privacy-policy', exact: true, name: 'Privacy Policy | Ming Microsystems', component: PrivacyPage },
    { path: '/support', exact: true, name: 'Support | Ming Microsystems', component: SupportPage },
    { path: '/business-dev-associate', exact: true, name: 'Business Development Associate | Ming Microsystems', component: BusinessDevAssociatePage },
    { path: '/signup', exact: true, name: 'Qwisely by Ming Microsystems', component: Signup },
    { path: '/signup-confirmation', exact: true, name: 'Qwisely by Ming Microsystems', component: SignupConfirmation },
    { path: '/review/:businessId/:reviewClientId', exact: true, name: 'Patient Review', component: ReviewForm }
];

export default route;
